<template>
  <div class="custom-landing" id="customLanding">
    <div class="landing-container" id="landingContainer">
      <ul>
        <li @click="handleCheckbox('above')" :class="{ checked: checkValue === 'above', red: choose }">
          <span>低学历升专科</span>
        </li>
        <li @click="handleCheckbox('below')" :class="{ checked: checkValue === 'below', red: choose }">
          <span>专科升本科</span>
        </li>
        <!-- <li @click="handleCheckbox('country')" :class="{ checked: checkValue === 'country', red: choose }">
          <span>国开</span>
        </li> -->
      </ul>
      <button @click="handleSubmit(false)"></button>
      <div class="education">
        <div @click="handleBtn(true)" class="education-item"></div>
        <div @click="handleBtn(true)" class="education-item"></div>
        <div @click="handleBtn(true)" class="education-item"></div>
        <div @click="handleBtn(true)" class="education-item"></div>
        <div @click="handleBtn(true)" class="education-item"></div>
        <div @click="handleBtn(true)" class="education-item"></div>
      </div>
      <button @click="handleBtn(true)" class="btn-one"></button>
      <div @click="handleBtn(true)" class="module"></div>
      <button @click="handleBtn(true)" class="btn-two"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      checkValue: undefined,
      choose: false
    }
  },
  methods: {
    handleCheckbox(param) {
      this.choose = false
      this.checkValue = this.checkValue === param ? undefined : param
    },
    handleSubmit(isLastBtn) {
      if (!this.checkValue) {
        this.choose = true
        if (isLastBtn) {
          document.getElementById('landingContainer').scrollIntoView()
        }
        return this.$message.error('请选择咨询学历')
      }
      const queryParam = this.$route.query
      let id = queryParam.id1
      if (this.checkValue === 'below') {
        id = queryParam.id2
      } else if (this.checkValue === 'country') {
        id = queryParam.id3
      }
      window.location.href = `${window.location.origin}/receiveFans?id=${id}&link=${encodeURIComponent(window.location.href)}`
    },
    handleBtn(isLastBtn) {
      if (!this.checkValue) {
        this.choose = true
        if (isLastBtn) {
          document.getElementById('customLanding').scrollIntoView()
        }
        return this.$message.error('请选择咨询学历')
      }
    }
  }
}
</script>

<style scoped lang="less">
.custom-landing {
  position: relative;
  max-width: 100vw;
  height: 0;
  margin: auto;
  padding-bottom: 478%;
  background: url("~@/assets/images/group.jpg") no-repeat top center/100% auto;

  .landing-container {
    position: absolute;
    top: 20.5%;
    right: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    margin: 0 auto;

    ul {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 60%;
      margin: auto;
      padding: 5vw 0 0;

      li {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 4vw;
        // margin-bottom: 3vw;
        border-radius: 4.8vw;
        font-size: 5.18vw;
        line-height: 12vw;
        margin-bottom: 20px;
        justify-content: center;
        text-align: center;
        color: #fff;
        font-weight: bold;
        background: url("~@/assets/images/group-btn.png") no-repeat top center/100% auto;

        &.checked {
          color: #fff;
          background: url("~@/assets/images/group-active.png") no-repeat top center/100% auto;
        }

        &.red {
          color: #fff;
          background: url("~@/assets/images/group-red.png") no-repeat top center/100% auto;
        }

        >span {
          flex: 1;
          margin-right: 20px
        }
      }
    }

    button {
      position: absolute;
      top: 41vw;
    }

    .btn-one {
      top: 143vw;
      background: url("~@/assets/images/group-one.png") no-repeat top center/100% auto;
    }

    .btn-two {
      top: 360vw;
      background: url("~@/assets/images/group-two.png") no-repeat top center/100% auto;
    }
  }

  button {
    right: 0;
    left: 0;
    width: 75%;
    height: 0;
    margin: 0 auto;
    padding-bottom: 16.6%;
    border: none;
    outline: none;
    background: url("~@/assets/images/group-submint.png") no-repeat top center/100% auto;
  }

  .landing-btn {
    position: absolute;
    top: 302vw;
  }

  .module {
    position: absolute;
    top: 236vw;
    opacity: 0;
    margin: 0 15%;
    padding-bottom: 88%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
  }

  .education {
    position: absolute;
    top: 83vw;
    opacity: 0;
    margin: 0 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 70%;

    .education-item {
      width: 33.3%;
      padding-bottom: 40%;
    }
  }
}

@media (min-width:750px) {
  .custom-landing {
    max-width: 750px;
    padding-bottom: 3585px;

    .landing-container {
      top: 15.5%;

      ul {
        width: 62%;
        padding: 202px 56px 0;

        li {
          padding: 0 32px;
          margin-bottom: 34px;
          font-size: 24px;
          line-height: 80px;
          margin-top: 34px;
        }
      }

      button {
        top: 500px;
      }

      .btn-one {
        top: 1228px;
        background: url("~@/assets/images/group-one.png") no-repeat top center/100% auto;
      }

      .btn-two {
        top: 2900px;
        background: url("~@/assets/images/group-two.png") no-repeat top center/100% auto;
      }
    }

    .landing-btn {
      top: 2272px;
    }

    .module {
      top: 1950px;
    }

    .education {
      top: 788px;
    }
  }
}
</style>