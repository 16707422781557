import { request } from "@/plugins/axios"

//专业列表
export const getMajorList = params => {
    return request({
        url: '/armyCivilian/majorList',
        method: 'GET',
        params
    })
}
//岗位列表
export const getPositionList = params => {
    return request({
        url: '/armyCivilian/positionList',
        method: 'GET',
        params
    })
}
//岗位详情
export const getPositionInfo = params => {
    return request({
        url: '/armyCivilian/positionInfo',
        method: 'GET',
        params
    })
}
//地区
export const getAreaList = params => {
    return request({
        url: '/armyCivilian/areaList',
        method: 'GET',
        params
    })
}
//报名大数据
export const getRegistrationCount = params => {
    return request({
        url: '/armyCivilian/registrationCount',
        method: 'GET',
        params
    })
}
//单位列表
export const getCompanyList = params => {
    return request({
        url: '/armyCivilian/positionInformationCompanyList',
        method: 'GET',
        params
    })
}