<template>
  <div class="custom-landing">
    <div class="landing-container" id="landingContainer">
      <ul>
        <li @click="handleCheckbox('above')" :class="{checked:checkValue === 'above'}">
          <span>18岁以上（自考/国开/成考）</span>
          <a-icon type="check" v-if="checkValue === 'above'"/>
        </li>
        <li @click="handleCheckbox('below')" :class="{checked:checkValue === 'below'}">
          <span>18岁以下（全日制）</span>
          <a-icon type="check" v-if="checkValue === 'below'"/>
        </li>
      </ul>
      <button @click="handleSubmit(false)"></button>
    </div>
    <button class="landing-btn" @click="handleSubmit(true)"></button>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      checkValue: undefined
    }
  },
  methods:{
    handleCheckbox(param) {
      this.checkValue = this.checkValue === param ? undefined : param
    },
    handleSubmit(isLastBtn) {
      if (!this.checkValue) {
        if (isLastBtn) {
          document.getElementById('landingContainer').scrollIntoView()
        }
        return this.$message.error('请选择咨询学历')
      }
      const queryParam = this.$route.query
      let id = queryParam.id1
      if (this.checkValue === 'below') {
        id = queryParam.id2
      }
      window.location.href = `${window.location.origin}/receiveFans?id=${id}&link=${encodeURIComponent(window.location.href)}`
    }
  }
}
</script>

<style scoped lang="less">

.custom-landing{
  position:relative;
  max-width: 100vw;
  height:0;
  margin:auto;
  padding-bottom:332.4%;
  background:url("~@/assets/images/landing-img.jpg") no-repeat top center/100% auto;
  .landing-container{
    position: absolute;
    top: 81vw;
    right: 0;
    left: 0;
    width: 100%;
    height:0;
    padding-bottom:66.8%;
    margin: 0 auto;
    border-radius: 12px;
    background:url("~@/assets/images/landing-icon.png") no-repeat top center/100% auto;
    ul{
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      width:67%;
      margin:auto;
      padding:25.2vw 0 0;
      li{
        display:flex;
        align-items:center;
        width:100%;
        padding: 0 4vw;
        margin-bottom: 3vw;
        border-radius: 4.8vw;
        font-size: 4.18vw;
        line-height: 12.6vw;
        color:#414141;
        background-color:#f2f2f2;
        &.checked{
          color:#fff;
          background-color:#c60c1a;
        }
        >span{
          flex:1;
        }
      }
    }
    button{
      position: absolute;
      top: 64vw;
    }
  }
  button{
    right:0;
    left:0;
    width:65%;
    height:0;
    margin:0 auto;
    padding-bottom:13.6%;
    border:none;
    outline:none;
    background:url("~@/assets/images/landing-btn.gif") no-repeat top center/100% auto;
  }
  .landing-btn{
    position:absolute;
    top:302vw;
  }
}
@media (min-width:750px){
  .custom-landing{
    max-width: 750px;
    padding-bottom:2493px;
    .landing-container{
      top:608px;
      ul{
        width:76%;
        padding: 202px 56px 0;
        li{
          padding: 0 32px;
          margin-bottom: 24px;
          font-size: 24px;
          line-height: 68px;
        }
      }
      button{
        top:482px;
      }
    }
    .landing-btn{
      top: 2272px;
    }
  }
}
</style>