import { request } from "@/plugins/axios"

// /**
//  * 获取落地页 详情
//  * @param {String} landingPageId 
//  */
// export const getDetail = landingPageId => {
//   return request({
//     url: '/landingPage/show',
//     method: 'GET',
//     params: {
//       landingPageId
//     }
//   })
// }

export function landingPageShow(params) {
  return request({
    url: '/landingPage/show',
    method: 'GET',
    params
  })
}

export function landingPageClick(params) {
  return request({
    url: '/landingPage/click',
    method: 'post',
    data: params
  })
}