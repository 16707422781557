<template>
  <div class="custom-landing" @scroll="handleScroll($event)">
    <div class="title" @click="goBack()">
      <div><img class="back" src="../../static/images/leftArrow.png" /></div>
      岗位列表
    </div>
    <div class="search">
      <div class="city" @click="goCity">
        <div class="city-name">{{ areaList ? areaList : '全国' }}</div>
        <div class="triangle"></div>
      </div>
      <img class="search-img" src="../../static/images/search.png" />
      <input
        type="text"
        @blur="change"
        @change="change"
        @input="change"
        v-model="name"
        class="search-input"
        placeholder="输入岗位名称"
      />
    </div>
    <div class="post">
      <div
        class="specialty-list"
        v-for="item in postList"
        :key="item.value"
        :class="postIndex === item.value ? 'specialty-active' : ''"
        @click="goPost(item.value)"
      >
        <span>{{ item.label }}</span>
      </div>
    </div>
    <div class="post-hint">
      为您匹配到<span>{{ total }}</span
      >个岗位
    </div>
    <div class="post-list" v-if="jobList && jobList.length > 0">
      <div v-for="item in jobList" :key="item.value" class="list" @click="goDetail(item)">
        <div class="list-heard">
          <div class="list-tit">{{ item.positionName }}</div>
          <div class="list-tag">{{ item.positionCategory }}</div>
        </div>
        <div class="list-city">
          {{ item.workingConditions2 }} | 招录数
          <span class="red">{{ item.candidatesNumber }}</span> | 入围比
          <span class="red">{{ item.percentageFinalists }}</span>
        </div>
        <div class="list-label">
          <div v-for="(it, index) in item.condition" :key="index" class="label-name">
            {{ it }}
          </div>
        </div>
        <div class="list-desc">{{ item.employerName }}</div>
      </div>
      <div
        v-if="isData"
        style="margin-top: 10px; font-size: 14px; text-align: center; margin-bottom: 20px"
      >
        没有更多数据了
      </div>
      <!-- <div
        v-if="!isData"
        style="margin-top: 10px; font-size: 14px; text-align: center; margin-bottom: 20px"
      >
        数据加载中。。。
      </div> -->
    </div>
    <div v-else style="margin-top: 50px; font-size: 20px; text-align: center">
      暂无数据
    </div>
  </div>
</template>

<script>
import { getPositionList } from '@/api/army'
export default {
  name: 'index',
  data() {
    return {
      name: '',
      postIndex: '1',
      postList: [
        {
          value: '1',
          label: '管理岗',
        },
        {
          value: '2',
          label: '技能岗',
        },
      ],
      jobList: [],
      loadingMore: false,
      queryParam: {
        page: 1,
        size: 10,
      },
      total: 0,
      totalPage: 0,
      isData: false,
      areaList: '',
    }
  },
  mounted() {
    this.jobList = []
    this.areaList = this.$route.query.areaList ? this.$route.query.areaList : ''
    this.getPositionList()
  },
  methods: {
    getPositionList() {
      getPositionList({
        page: this.queryParam.page,
        perPage: this.queryParam.size,
        positionName: this.name,
        major: this.$route.query.name,
        area: this.areaList
      }).then((res) => {
        const list = res.data.list
        for (const item of list) {
          this.jobList.push(item)
        }
        // this.jobList = list
        this.total = res.data.page.total
        this.totalPage = res.data.page.totalPage
        if (this.totalPage <= this.queryParam.page) {
          this.isData = true
        } else {
          this.isData = false
        }
      })
    },
    goPost(value) {
      this.jobList = []
      this.postIndex = value
      if (value === '1') {
        this.getPositionList()
      } else {
        this.total = 0
      }
    },
    change(value) {
      this.jobList = []
      ;(this.queryParam = {
        page: 1,
        size: 10,
      }),
        (this.name = value.target.value)
      this.getPositionList()
    },
    goBack() {
      this.$router.back()
    },
    handleScroll(e) {
      let clientHeight = e.target.clientHeight
      let scrollTop = e.target.scrollTop
      let scrollHeight = e.target.scrollHeight
      if (this.totalPage <= this.queryParam.page) {
        return
      }
      if (clientHeight + scrollTop >= scrollHeight - 10) {
        this.loadingMore = true
        this.queryParam.page++
        this.getPositionList()
      }
    },
    goDetail(item) {
      this.$router.push({ path: 'zhuyuanArmyDetail', query: { id: item.id } })
    },
    goCity() {
      this.$router.replace({
        path: 'zhuyuanArmyCity',
        query: { name: this.$route.query.name, areaList: this.$route.query.areaList },
      })
    },
  },
}
</script>

<style scoped>
.custom-landing {
  width: 100%;
  overflow: scroll;
  height: 100vh;
  font-size: 16px;
}
.title {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: red;
  color: #fff;
  font-size: 20px;
  text-align: center;
  position: relative;
}
.back {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
}
.post {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  color: #666;
  border-bottom: 1px solid #e2e2e2;
  margin: 0 20px;
}
.search {
  margin: 20px;
  border-radius: 310px;
  background-color: #fff;
  display: flex;
  padding: 5px 10px;
  border: 1px solid #ececec;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
}
.search-img {
  width: 20px;
  height: 20px;
  margin: 5px 10px 0 5px;
}

.city {
  width: 80px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
}

.city-name {
  max-width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid black;
  margin: 12px 5px 0 5px;
  text-align: right;
}
.search-input {
  border: none;
  width: 80%;
  height: 30px;
  line-height: 30px;
}
.search input:hover,
.search input:focus {
  border: none;
  border-color: #fff;
  background-color: #fff;
}
input[type='text']:hover,
input[type='text']:focus,
input[type='password']:hover {
  border: 1px solid #fff; /* 鼠标悬停边框样式 */
  background-color: #fff;
  border-color: #fff;
  outline: none; /* 移除默认的蓝色轮廓线 */
}
.specialty-list {
  font-size: 16px;
}
.specialty-active {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
.post-hint {
  height: 40px;
  line-height: 40px;
  background-color: #ffeded;
  color: #000;
  text-align: center;
  margin-top: 20px;
}
.post-hint span {
  color: red;
  font-size: 20px;
}
.post-list {
}
.list {
  margin: 20px 20px 0 20px;
  border: 1px solid #ececec;
  padding: 10px;
  border-radius: 5px;
}
.list-heard {
  display: flex;
  justify-content: space-between;
}
.list-tit {
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #000;
  font-size: 18px;
}
.list-tag {
  background-color: red;
  text-align: center;
  border-radius: 20px;
  padding: 0 10px;
  color: #fff;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
}
.list-city {
  margin: 10px 0;
  color: #999;
}
.list-label {
  display: flex;
  flex-wrap: wrap;
}
.label-name {
  border: 1px solid #929292;
  padding: 2px 5px;
  margin-right: 10px;
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
}
.list-desc {
  margin: 0 0 10px 0;
}
.red {
  color: red;
}
</style>
