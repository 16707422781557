import { request } from "@/plugins/axios"

//获取落地页详情
export const getLandingDetail = params => {
    return request({
        url: '/landingPage/show',
        method: 'GET',
        params
    })
}
//【表单】获取自定义表单详情
export const getCustomForm = params => {
    return request({
        url: '/customForm/show',
        method: 'GET',
        params
    })
}
//【记录】表单记录上报
export const customFormRecord = data => {
    return request({
        url: '/customFormRecord/store',
        method: 'POST',
        data
    })
}