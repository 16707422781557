<template>
  <div class="custom-landing">
    <div class="title" @click="goBack()">
      <div><img class="back" src="../../static/images/leftArrow.png" /></div>
      岗位详情
    </div>
    <div class="info">
      <div class="detail-tit">{{ positionInfo.positionName }}  {{ positionInfo.postCode }}</div>
      <div class="detail-hint">{{ positionInfo.employerName }} | {{ positionInfo.workingConditions }} | 招录{{ positionInfo.candidatesNumber }}人</div>
    </div>
    <div class="margin-top">
      <div class="detail-heard">
        <span class="icon">|</span>考情分析<span class="explain">数据说明</span>
      </div>
      <div class="content column">
        <div class="flex w50 margin-bottom">
          <div class="info-tit">招录人数</div>
          <div class="info-data red">{{ positionInfo.candidatesNumber }}</div>
        </div>
        <div class="flex w50 margin-bottom">
          <div class="info-tit">入围比例</div>
          <div class="info-data red">{{ positionInfo.percentageFinalists }}</div>
        </div>
        <div class="flex w50 margin-bottom">
          <div class="info-tit">考试科目</div>
          <div class="info-data red">{{ positionInfo.examinationSubject }}</div>
        </div>
        <div class="flex w50 margin-bottom">
          <div class="info-tit">岗位代码</div>
          <div class="info-data red">{{ positionInfo.postCode }}</div>
        </div>
        <div class="flex margin-bottom" style="width: 100%">
          <div class="info-tit">数据来源</div>
          <div class="info-data gray">{{ positionInfo.dataSource }}</div>
        </div>
        <div class="flex margin-bottom gray">数据更新时间：{{ positionInfo.updatedAt }}</div>
      </div>
    </div>
    <div class="margin-top">
      <div class="detail-heard"><span class="icon">|</span>岗位信息</div>
      <div class="content">
        <div class="flex margin-bottom">
          <div class="info-tit gray">岗位名称：</div>
          <div class="info-data">{{ positionInfo.positionName }}</div>
        </div>
        <div class="flex margin-bottom">
          <div class="info-tit gray">岗位代码：</div>
          <div class="info-data">{{ positionInfo.postCode }}</div>
        </div>
        <div class="flex margin-bottom">
          <div class="info-tit gray">岗位类别：</div>
          <div class="info-data">{{ positionInfo.positionCategory }}</div>
        </div>
        <div class="flex margin-bottom">
          <div class="info-tit gray">单位名称：</div>
          <div class="info-data">{{ positionInfo.employerName }}</div>
        </div>
        <div class="flex margin-bottom">
          <div class="info-tit gray">单位序号：</div>
          <div class="info-data">{{ positionInfo.employerNumber }}</div>
        </div>
        <div class="flex margin-bottom">
          <div class="info-tit gray">从事工作：</div>
          <div class="info-data">{{ positionInfo.engagedWork }}</div>
        </div>
        <div class="flex margin-bottom">
          <div class="info-tit gray">工作地点：</div>
          <div class="info-data">{{ positionInfo.workingConditions2 }}</div>
        </div>
        <div class="flex margin-bottom">
          <div class="info-tit gray">岗位报名人数：</div>
          <div class="info-data red">{{ positionInfo.bkrs }}人</div>
        </div>
      </div>
    </div>
    <div class="margin-top">
      <div class="detail-heard"><span class="icon">|</span>报考条件</div>
      <div class="content">
        <div class="flex margin-bottom">
          <div class="info-tit gray">来源类别：</div>
          <div class="info-data red">{{ positionInfo.sourceCategory }}</div>
        </div>
        <div class="flex margin-bottom">
          <div class="info-tit gray">专业要求：</div>
          <div class="info-data red" v-html="positionInfo.major"></div>
        </div>
        <div class="flex margin-bottom">
          <div class="info-tit gray">学历要求：</div>
          <div class="info-data red">{{ positionInfo.education }}</div>
        </div>
        <div class="flex margin-bottom">
          <div class="info-tit gray">学位要求：</div>
          <div class="info-data red">{{ positionInfo.academicDegree }}</div>
        </div>
        <div class="flex margin-bottom">
          <div class="info-tit gray">专业技术资格：</div>
          <div class="info-data red">{{ positionInfo.title }}</div>
        </div>
        <div class="flex margin-bottom">
          <div class="info-tit gray">职业资格：</div>
          <div class="info-data red">{{ positionInfo.professionalQualification  }}</div>
        </div>
      </div>
    </div>
    <div class="margin-top padding-bottom-xl">
      <div class="detail-heard">
        <span class="icon">|</span>其他要求:<span class="hint"
          >以下条件未全匹配上请仔细核对否则会选岗失败</span
        >
      </div>
      <div class="content red">
        {{ positionInfo.otherConditions }}
      </div>
    </div>
  </div>
</template>

<script>
import { getPositionInfo } from '@/api/army'
export default {
  name: 'index',
  data() {
    return {
      positionInfo: {},
    }
  },
  mounted() {
    this.getPositionInfo()
  },
  methods: {
    getPositionInfo() {
      const that = this
      getPositionInfo({
        id: that.$route.query.id,
      }).then((res) => {
        that.positionInfo = res.data
      })
    },
    goBack() {
      this.$router.back()
    },
  },
}
</script>

<style scoped>
.custom-landing {
  width: 100%;
  color: #000;
  font-size: 16px;
}
.title {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: red;
  color: #fff;
  font-size: 20px;
  text-align: center;
  position: relative;
}
.back {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
}
.info {
  border: 1px solid #ececec;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
}
.detail-tit {
  color: #000;
  font-size: 18px;
  font-weight: bold;
}
.detail-hint {
  color: #999;
  margin-top: 5px;
}
.margin-top {
  margin-top: 20px;
}
.detail-heard {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  margin: 0 20px;
}
.detail-heard .icon {
  color: red;
  margin-right: 10px;
  font-size: 20px;
}
.detail-heard .explain {
  color: #666;
  font-size: 14px;
  margin-left: 10px;
  font-weight: normal;
}
.detail-heard .hint {
  color: #666;
  font-size: 12px;
  margin-left: 10px;
  font-weight: normal;
  background-color: #ffeded;
}
.content {
  margin: 20px 20px 0 20px;
  font-size: 14px;
}

.flex {
  display: flex;
}
.info-tit {
  width: 80px;
}
.w50 {
  width: 50%;
}
.column {
  display: flex;
  flex-wrap: wrap;
}
.margin-bottom {
  margin-bottom: 10px;
}
.red {
  color: red;
}
.info-data {
  margin-right: 10px;
  width: calc(100% - 80px);
}
.gray {
  color: #999;
}
.padding-bottom-xl {
  padding-bottom: 50px;
}
</style>
