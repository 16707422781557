<template>
  <div class="custom-landing">
    <div class="landing-container">
      <div class="landing-content">
        <a-form
          :form="form"
        >
          <template v-for="(item,index) in formList">
            <a-form-item :label="item.label" :key="index">
              <template v-if="item.type==='input'">
                <template v-if="item.attrs.inputType==='input'">
                  <a-input
                      v-decorator="handleDecorator(item)"
                      :placeholder="item.attrs.placeholder"
                      :disabled="item.attrs.disabled"
                  />
                </template>
                <template v-else-if="item.attrs.inputType==='number'">
                  <a-input-number
                      v-decorator="handleDecorator(item)"
                      :placeholder="item.attrs.placeholder"
                      :disabled="item.attrs.disabled"
                      :min="item.attrs.min"
                      :max="item.attrs.max"
                      style="width:100%;"
                  />
                </template>
                <template v-else-if="item.attrs.inputType==='password'">
                  <a-input-password
                      v-decorator="handleDecorator(item)"
                      :placeholder="item.attrs.placeholder"
                      :disabled="item.attrs.disabled"
                  />
                </template>
              </template>
              <template v-else-if="item.type==='select'">
                <template v-if="item.attrs.selectType==='select'">
                  <a-select
                      v-decorator="handleDecorator(item)"
                      :disabled="item.attrs.disabled"
                      :placeholder="item.attrs.placeholder"
                      :mode="item.attrs.multiple?'multiple':'default'"
                      :allowClear="item.attrs.clearable">
                    <a-select-option
                        v-for="(minItem,minIndex) in item.attrs.options"
                        :key="minIndex"
                        :value="minItem.value"
                    >
                      {{minItem.label}}
                    </a-select-option>
                  </a-select>
                </template>
                <template v-else-if="item.attrs.selectType==='cascader'">
                  <a-cascader
                      v-decorator="handleDecorator(item)"
                      :options="item.attrs.options"
                      :placeholder="item.attrs.placeholder"
                      :disabled="item.attrs.disabled"/>
                </template>
              </template>
            </a-form-item>
          </template>
        </a-form>
      </div>
      <a-button type="primary" @click="handleSubmit" :loading="loading">
        <span class="icon"></span>
        获取免费评估
      </a-button>
    </div>
  </div>
</template>

<script>
import {getLandingDetail, getCustomForm, customFormRecord} from '@/api/customLanding'
import {landingPageClick} from '@/api/landingPage'
export default {
  name: "index",
  data() {
    return {
      form: this.$form.createForm(this),
      formList: [], //自定义表单数据
      formId: undefined, //表单id
      jumpUrl: undefined, //跳转链接
      loading: false,
    }
  },
  methods:{
    // 表单校验
    handleDecorator(item){
      return [
        `${item.name}`,
        {
          rules: item.attrs.rules,
          initialValue: item.attrs.defaultValue
        },
      ]
    },
    // 获取自定义表单id
    getLandingDetail(){
      getLandingDetail({
        landingPageId:this.$route.query.id
      }).then(res=>{
        const resData = res.data
        this.formId = resData.customFormId
        this.jumpUrl = resData.jumpUrl
        this.getCustomForm()
      })
    },
    // 获取自定义表单
    getCustomForm() {
      getCustomForm({id:this.formId}).then(res=>{
        this.formList = res.data.elList
      })
    },
    // 点击数
    landingPageClick() {
      landingPageClick({
        id: this.$route.query.id,
        link: encodeURIComponent(window.location.href)
      }).then(res=>{
        console.log(res)
      })
    },
    // 提交
    handleSubmit() {
      if(this.loading) {
        return
      }
      this.form.validateFields((err,values)=>{
        if(err) { return }
        const sourceId = this.$route.query.id
        this.loading = true
        this.landingPageClick()
        Object.keys(values).forEach(key => {
          if(values[key] === undefined){
            values[key] = ''
          }
        })
        customFormRecord({
          formId: this.formId,
          source: 'landingPage',
          sourceId: sourceId,
          data: values
        }).then(res => {
          window.location.href = `${this.jumpUrl}&link=${encodeURIComponent(window.location.href)}&customFormRecordId=${res.data.id}`
        }).finally(() => {
          this.loading = false
        })
      })
    }
  },
  created() {
    this.getLandingDetail()
    document.querySelector("meta[name=viewport]").setAttribute("content","width=device-width,initial-scale=1.0,user-scalable=no, minimum-scale=1.0, maximum-scale=1.0")
  }
}
</script>

<style scoped lang="less">
.custom-landing{
  position:relative;
  max-width: 100vw;
  height:0;
  margin:auto;
  padding-bottom:196.13%;
  background:url("~@/assets/images/landing-background.jpg") no-repeat top center/100% auto;
  .landing-container{
    position: absolute;
    top: 48vw;
    right: 0;
    left: 0;
    width: 88%;
    height:0;
    padding-bottom:136%;
    margin: 0 auto;
    border-radius: 12px;
    background-color: #fff;
    button{
      position:absolute;
      right:0;
      bottom:9vw;
      left:0;
      display:flex;
      align-items:center;
      justify-content: center;
      width:172px;
      height:40px;
      margin:0 auto;
      font-size:16px;
      font-weight:bold;
      border-radius:20px;
      background-color:#2661f9;
    }
    .icon{
      width:18px;
      height:18px;
      margin-right:8px;
      background:url("~@/assets/images/paper-airplanes.svg") no-repeat center/contain;
    }
  }
  /deep/.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only){
    width:180px;
  }
  .landing-content{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    padding:6vw 6vw 26vw;
  }
  /deep/.ant-form{
    height:100%;
    overflow:auto;
    .ant-form-item{
      margin-bottom: 4vw;
      &:last-child{
        margin-bottom:0;
      }
      label{
        font-weight:bold;
      }
      &-label{
        padding-bottom:1vw;
      }
    }
    .ant-form-item-required::before{
      content:unset;
    }
    .ant-form-item-required::after {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-weight:bold;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}
@media (min-width:390px){
  .custom-landing{
    .landing-container{
      button{
        bottom:10vw;
      }
    }
    .landing-content{
      padding-top:8vw;
    }
  }
}
@media (min-width:750px){
  .custom-landing{
    max-width: 750px;
    .landing-container{
      top: 364px;
      button{
        bottom:164px;
        width:186px;
        height:40px;
        font-size:16px;
        border-radius:20px;
      }
      .icon{
        width:20px;
        height:20px;
      }
    }
    /deep/.ant-form{
      label{
        font-size:16px;
      }
      .ant-form-item{
        margin-bottom: 24px;
        &-label{
          padding-bottom:8px;
        }
      }
    }
  }
}
</style>
<style>
.ant-cascader-menu-item{
  max-width:40vw;
  white-space: normal;
  word-break: break-all;
}
.ant-select-dropdown-menu-item{
  overflow: initial;
  white-space: initial;
  text-overflow: initial;
}
</style>