<template>
  <div class="custom-landing">
    <div class="title" @click="goBack()">
      <div><img class="back" src="../../static/images/leftArrow.png" /></div>
      专业选择
    </div>
    <div class="specialty">
      <div
        class="specialty-list"
        v-for="item in specialty"
        :key="item.number"
        :class="specialtyIndex === item.number ? 'specialty-active' : ''"
        @click="goSecialty(item.number)"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="custom-border"></div>
    <div class="specialty-info">
      <div class="specialty-item">
        <div
          class="college-list"
          v-for="item in specialtyOne"
          :key="item.number"
          :class="specialtyOneIndex === item.number ? 'college-active' : ''"
          @click="goSecialtyOne(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="specialty-item">
        <div
          class="college-list"
          v-for="item in specialtyTwo"
          :key="item.number"
          :class="specialtyTwoIndex === item.number ? 'college-active' : ''"
          @click="goSecialtyTwo(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="specialty-item">
        <div
          class="college-list"
          v-for="item in specialtyThree"
          :key="item.number"
          :class="specialtyThreeIndex === item.number ? 'college-active' : ''"
          @click="goSecialtyThree(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="specialty-btn">
      <div class="btn" @click="handleSubmit">确定</div>
    </div>
    <div class="custon-modal" id="modalMask" v-show="visible">
      <div class="text">请选择专业</div>
    </div>
  </div>
</template>

<script>
import { getMajorList } from '@/api/army'
export default {
  name: 'index',
  data() {
    return {
      publicIndex: '',
      specialtyIndex: '',
      specialty: [],
      specialtyOneIndex: '',
      specialtyOneName: '',
      specialtyOne: [],
      specialtyTwoIndex: '',
      specialtyTwoName: '',
      specialtyTwo: [],
      specialtyThreeIndex: '',
      specialtyThreeName: '',
      specialtyThree: [],
      visible: false,
    }
  },
  mounted() {
    this.getSpecialtyList()
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    getSpecialtyList() {
      getMajorList().then((res) => {
        this.specialty = res.data.list
        this.specialtyIndex = this.specialty[0].number
        this.publicIndex = this.specialty[0].number
        this.specialtyOne = []
        this.specialtyTwo = []
        this.specialtyThree = []
        this.getList(1)
      })
    },
    getList(type) {
      getMajorList({
        pNumber: this.publicIndex,
      }).then((res) => {
        const list = res.data.list
        if (type === 1) {
          this.specialtyOne = list
          this.specialtyTwo = []
          this.specialtyThree = []
        } else if (type === 2) {
          this.specialtyTwo = list
          this.specialtyThree = []
        } else {
          this.specialtyThree = list
        }
      })
    },
    goSecialty(value) {
      this.specialtyIndex = value
      this.publicIndex = value
      this.getList(1)
    },
    goSecialtyOne(item) {
      this.specialtyOneIndex = item.number
      this.specialtyOneName = item.name
      this.publicIndex = item.number
      this.getList(2)
    },
    goSecialtyTwo(item) {
      this.specialtyTwoIndex = item.number
      this.specialtyTwoName = item.name
      this.publicIndex = item.number
      this.getList(3)
    },
    goSecialtyThree(item) {
      this.specialtyThreeIndex = item.number
      this.specialtyThreeName = item.name
      this.publicIndex = item.number
    },
    handleSubmit() {
      const that = this
      if (!this.specialtyOneName || !this.specialtyTwoName || !this.specialtyThreeName) {
        this.visible = true
        setTimeout(function () {
          that.visible = false
        }, 1000)
      } else {
        const name =
          this.specialtyOneName +
          ',' +
          this.specialtyTwoName +
          ',' +
          this.specialtyThreeName
        this.$router.push({ path: 'zhuyuanArmyList', query: { name: name } })
      }
    },
  },
}
</script>

<style scoped>
.custom-landing {
  width: 100%;
  height: 100vh;
  font-size: 16px;
}

.title {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: red;
  color: #fff;
  font-size: 20px;
  text-align: center;
  position: relative;
}
.back {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
}
.specialty {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  color: #666;
}
.custom-border {
  width: 100%;
  background-color: #f2f2f2;
  height: 10px;
  line-height: 20px;
}
.specialty-list {
  font-size: 18px;
}
.specialty-active {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
.specialty-info {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 50px - 10px - 60px - 45px);
}

.specialty-item {
  width: 33.3%;
  height: calc(100vh - 50px - 10px - 60px - 45px);
  overflow-y: auto;
}
.college-list {
  /* height: 40px;
  line-height: 40px; */
  font-size: 16px;
  padding: 12px 20px;
}
.college-list.college-active {
  background-color: #f7f7f7;
  border-left: 2px solid red;
  color: red;
}
.specialty-btn {
  background-color: #f2f2f2;
  padding: 5px 20px;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  display: flex;
  width: 100%;
}
.specialty-btn .btn {
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: red;
  text-align: center;
  border-radius: 5px;
  width: 100%;
}
.custon-modal {
  height: 45px;
  line-height: 45px;
  text-align: center;
  width: 100%;
  position: fixed;
  top: 45%;
  justify-content: center;
  display: flex;
  visibility: 'hidden';
}

.custon-modal .text {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  width: 180px;
  font-size: 16px;
  color: #fff;
  text-align: center;
}
</style>
