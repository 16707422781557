<template>
  <div class="custom-landing">
    <div class="title" @click="goBack()">
      <div><img class="back" src="../../static/images/leftArrow.png" /></div>
      选择城市
    </div>
    <div class="">
      <div class="city-num">已选{{ areaList.length ? areaList.length : 0 }}个地区:</div>
      <div class="city">
        <div v-for="(item, index) in areaList" :key="index" class="city-check" @click="goDelete(index)">
          {{ item }}
        </div>
      </div>
    </div>
    <div class="border">
      <div class="city-num">
        地区选择:<span
          style="color: #999; font-size: 12px; font-weight: normal; margin-left: 10px"
          >当前最多可选10个</span
        >
      </div>
    </div>
    <div class="specialty-info">
      <div class="specialty-provice">
        <div
          class="college-list"
          v-for="item in provinceList"
          :key="item.id"
          :class="pId === item.id ? 'college-active' : ''"
          @click="goProvince(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="specialty-item bg">
        <div
          class="college-list"
          v-for="item in cityList"
          :key="item.id"
          :class="cityIndex === item.id ? 'city-active' : ''"
          @click="goCity(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="specialty-btn">
      <div class="refresh" @click="goRefresh">清空</div>
      <div class="btn" @click="handleSubmit">确定</div>
    </div>
    <div class="custon-modal" id="modalMask" v-show="visible">
      <div class="text">当前最多可选10个地区</div>
    </div>
  </div>
</template>

<script>
import { getAreaList } from '@/api/army'
export default {
  name: 'index',
  data() {
    return {
      pId: '',
      cityIndex: '',
      areaList: [],
      provinceList: [],
      cityList: [],
      visible: false,
    }
  },
  mounted() {
    this.jobList = []
    if (this.$route.query.areaList) {
      this.areaList = this.$route.query.areaList.split(',')
    }
    this.getAreaList()
  },
  methods: {
    getAreaList() {
      getAreaList().then((res) => {
        const list = res.data.list
        this.provinceList = list
      })
    },
    getCityList() {
      getAreaList({
        pId: this.pId,
      }).then((res) => {
        const list = res.data.list
        this.cityList = list
      })
    },
    goProvince(value) {
      this.pId = value.id
      this.getCityList()
    },
    goCity(value) {
      const that = this
      this.cityIndex = value.id
      if (this.areaList && this.areaList.length >= 10) {
        this.visible = true
        setTimeout(function () {
          that.visible = false
        }, 1000)
        return
      }
      if (this.areaList.includes(value.name)) {
        const areaList = JSON.parse(JSON.stringify(this.areaList))
        this.areaList = areaList.filter((item) => item !== value.name)
      } else {
        this.areaList.push(value.name)
      }
    },
    goDelete(index) { 
      this.areaList.splice(index, 1)
    },
    goBack() {
      this.$router.back()
    },
    goRefresh() {
      this.areaList = []
    },
    handleSubmit() {
      const areaList = this.areaList.toString()
      this.$router.replace({ path: 'zhuyuanArmyList', query: { name: this.$route.query.name, areaList: areaList} })
    },
  },
}
</script>

<style scoped>
.custom-landing {
  width: 100%;
  height: 100vh;
  font-size: 14px;
}

.back {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.title {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: red;
  color: #fff;
  font-size: 20px;
  text-align: center;
  position: relative;
}
.specialty {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-around;
  font-size: 18px;
  color: #666;
}
.custom-border {
  width: 100%;
  background-color: #f2f2f2;
  height: 10px;
  line-height: 20px;
}
.specialty-list {
  font-size: 18px;
}
.specialty-active {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
.specialty-info {
  display: flex;
  justify-content: space-between;
  /* height: calc(100vh - 300px); */
  padding-bottom: 60px;
}

.specialty-item {
  width: 60%;
  height: calc(100vh - 250px);
  overflow-y: auto;
  background-color: #f6f6f6;
}

.specialty-provice {
  width: 40%;
  overflow-y: auto;
  height: calc(100vh - 250px);
}
.college-list {
  /* height: 40px;
  line-height: 40px; */
  font-size: 16px;
  padding: 12px;
}
.college-list.college-active {
  background-color: #f7f7f7;
  border-left: 2px solid red;
  color: red;
}
.college-list.city-active {
  color: red;
}
.specialty-btn {
  background-color: #fff;
  padding: 5px 20px;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
}
.specialty-btn .btn {
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: red;
  text-align: center;
  border-radius: 5px;
  width: 80%;
}
.specialty-btn .refresh {
  height: 40px;
  line-height: 40px;
  color: #666;
  background-color: #dedede;
  text-align: center;
  border-radius: 5px;
  width: 20%;
  margin-right: 10px;
}
.city-num {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin: 20px;
}

.city {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}
.city-check {
  border: 1px solid red;
  color: red;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  margin: 0 10px 10px 0;
}
.border {
  border-bottom: 2px solid #ececec;
}
.custon-modal {
  height: 45px;
  line-height: 45px;
  text-align: center;
  width: 100%;
  position: fixed;
  top: 45%;
  justify-content: center;
  display: flex;
  visibility: 'hidden';
}

.custon-modal .text {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  width: 180px;
  font-size: 16px;
  color: #fff;
  text-align: center;
}
</style>
